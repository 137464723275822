import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    LOG_OUT,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUBMISSION_FAILURE,
    FORGOT_PASSWORD_SUBMISSION_REQUEST,
    FORGOT_PASSWORD_SUBMISSION_SUCCESS,
    FORGOT_PASSWORD_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_FAILURE,
    GET_USER_SUCCESS
} from "../constants/auth.constant";

const initialState = {
    user: {},
    loading: false,
    success: false,
    err: "",
};

export const authenticationReducer = (state = initialState, action) => {
    switch(action.type){
        case LOGIN_REQUEST:
            return { ...state,loading:true }
        case LOGIN_SUCCESS:
            return {...state, loading:false,user:action.payload,success:true}
        case LOGIN_FAIL:
            return {...state,loading:false,err:action.payload}
        case SIGNUP_REQUEST:
            return {...state,loading:true,user:{}}
        case SIGNUP_SUCCESS:
            return {...state,loading:false,user:action.payload,success:true}
        case SIGNUP_FAIL:
            return {...state,loading:false,err:action.payload}
        case LOG_OUT:
            return { ...initialState }
        case GET_USER_REQUEST:
            return {...state,loading:true}
        case GET_USER_SUCCESS:
            return {...state, loading:false, user:action.payload}
        case GET_USER_FAILURE:
            return {...state,loading:false, err: action.payload}
        case FORGOT_PASSWORD_SUBMISSION_REQUEST:
            return {...state, loading:true}
        case FORGOT_PASSWORD_SUBMISSION_SUCCESS:
            return {...state, loading:false, reset:true }
        case FORGOT_PASSWORD_SUBMISSION_FAILURE:
            return {...state, loading:false, err:action.payload }
        case FORGOT_PASSWORD_REQUEST:
            return {...state, loading: true }
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state,loading: false, success:true }
        case FORGOT_PASSWORD_FAILURE:
            return {...state,loading: false, err: 'Please check the mailId and try again'}
        default:
            return state;
    }
};
