export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOG_OUT = 'LOG_OUT';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_SUBMISSION_REQUEST = 'FORGOT_PASSWORD_SUBMISSION_REQUEST';
export const FORGOT_PASSWORD_SUBMISSION_SUCCESS = 'FORGOT_PASSWORD_SUBMISSION_SUCCESS';
export const FORGOT_PASSWORD_SUBMISSION_FAILURE ='FORGOT_PASSWORD_SUBMISSION_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
