import {
    GET_USERS_FAILURE,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,
    RESET_USER_SUCCESS,
    REMOVE_USER_FAILURE,
    REMOVE_USER_REQUEST,
    REMOVE_USER_SUCCESS
} from "../constants/user.constant";

const initialState = {
    users: [],
    totalNumberOfDocs: 0,
    loading: false,
    err: "",
    success: false,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return { ...state, loading: true };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.data,
                totalNumberOfDocs: action.payload.results,
            };
        case GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                err: "Some problem occured please retry!",
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                loading:true
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true
            }
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                loading:false,
                err: action.payload
            }
        case RESET_USER_SUCCESS:
            return {
                ...state,
                success:false
            }
        case REMOVE_USER_REQUEST:
            return {
                ...state,
                loading:true
            }
        case REMOVE_USER_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true
            }
        case REMOVE_USER_FAILURE:
            return {
                ...state,
                loading:false,
                err: action.payload
            }
        default:
            return state;
    }
};
