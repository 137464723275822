import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//=======styles =======
import "./assets/styles/inputfieldstyles.css";
import "./assets/styles/fontStyles.css";
import "./assets/styles/imageStyles.css";
import "./assets/styles/buttonStyles.css";
import "./assets/styles/customMUI.css";
import "./assets/styles/layoutStyles.css";

//importing loader
import Loader from './components/Loading';

//lazy loading pages//
const Login = lazy(() => import("./views/login"));
const SuccessPage = lazy(() => import("./views/Success"));
const Signup = lazy(() => import("./views/signup"));
const Home = lazy(() => import("./views/home"));
const Dashboard = lazy(() => import("./views/Owner/Dashboard"));
const Inventory = lazy(() => import("./views/Owner/Inventory"));
const OwnerProfile = lazy(() => import("./views/Owner/Profile"));
const Sendmail = lazy(() => import("./views/forgotpassword/Sendmail"));
const ResetPassword = lazy(() => import("./views/forgotpassword/ResetPassword"));
const EditProduct = lazy(() => import("./views/EditProduct"));
const AddProduct = lazy(() => import("./views/addProduct"));
const StoreSearch = lazy(() => import("./views/Owner/StoreSearch"));
//layout lazy loading
const Ownerlayout = lazy(() => import("./components/Ownerlayout"));

function App() {
    return (
        <>
            <BrowserRouter>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/login" Component={Login} />
                        <Route path="/signup" Component={Signup} />
                        <Route path="/" Component={Home} />
                        <Route path="/forgotpassword" Component={Sendmail} />
                        <Route
                            path="/resetPassword/:token"
                            Component={ResetPassword}
                        />
                        <Route path="/owner" element={<Ownerlayout />}>
                            <Route index element={<Dashboard />} />
                            <Route
                                path="dashboard/purchases"
                                element={<Dashboard />}
                            />
                            <Route
                                path="dashboard/users"
                                element={<Dashboard />}
                            />
                            <Route path="profile" element={<OwnerProfile />} />
                            <Route path="profile/users" element={<OwnerProfile />} />
                            <Route path="inventory" element={<Inventory />} />
                            <Route
                                path="dashboard/purchases/addproduct"
                                element={<AddProduct />}
                            />
                            <Route
                                path="dashboard/purchases/addproduct/:id"
                                element={<AddProduct />}
                            />
                            <Route
                                path="dashboard/purchases/editproduct/:id"
                                element={<EditProduct />}
                            />
                            <Route
                                path="searchstores/stores"
                                element={<StoreSearch />}
                            />
                            <Route
                                path="searchstores/search"
                                element={<StoreSearch />}
                            />
                        </Route>
                        Suspense
                        <Route path="/success" Component={SuccessPage} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>
    );
}

export default App;
