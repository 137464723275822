import {
    ADD_PRODUCT_FAILURE,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    PRODUCT_DETAILS_FAILURE,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    EDIT_PRODUCT_FAILURE,
    EDIT_PRODUCT_REQUEST,
    EDIT_PRODUCT_SUCCESS,
    RESET_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    GET_SUPPLIER_FAILURE,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_SUCCESS
} from "../constants/product.constant";

const initialState = {
    productDetails: {},
    loading: false,
    success: false,
    err: "",
    suppliers:[]
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_REQUEST:
            return {
                ...state,
                loading:true
            }
        case ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                err: ''
            }
        case ADD_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                err: "We are having a problem right now please try again after sometime!"
            }
        case GET_SUPPLIER_REQUEST:
            return {
                ...state,
                loading:true
            }
        case GET_SUPPLIER_SUCCESS:
            return {
                ...state,
                loading:false,
                suppliers: action.payload
            }
        case GET_SUPPLIER_FAILURE:
            return {
                ...state,
                loading:false,
                err: action.payload
            }
        case PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                productDetails: action.payload,
                err: '',
            }
        case PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload
            }
        case EDIT_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case EDIT_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                err: ''
            }
        case EDIT_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload
            }
        case RESET_SUCCESS:
            return {
                ...state,
                success: false,
                err: ''
            }
        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                err: ''
            }
        case DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload
            }
        default:
            return state;
    }
};
