import {
    PRODUCT_SEARCH_FAILURE,
    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    ADD_SALES_PRODUCT_FAILURE,
    ADD_SALES_PRODUCT_REQUEST,
    ADD_SALES_PRODUCT_SUCCESS,
    REMOVE_SALES_PRODUCT_SUCCESS,
    REMOVE_SALES_PRODUCT_FAILURE,
    REMOVE_SALES_PRODUCT_REQUEST,
    CREATE_SALES_FAILURE,
    CREATE_SALES_REQUEST,
    CREATE_SALES_SUCCESS,
    GET_SALES_FAILURE,
    GET_SALES_REQUEST,
    GET_SALES_SUCCESS,
    GET_SALE_DETAILS,
    SCAN_BARCODE_FAILURE,
    SCAN_BARCODE_REQUEST,
    SCAN_BARCODE_SUCCESS,
    RESET_SUCCESS,
    CLEAR_SEARCH_RESULT,
    CLEAR_PRODUCTS
} from "../constants/sales.constant";

const initialState = {
    saleDetails: {},
    salesData: [],
    totalNumberOfDocs: 0,
    products: [],
    addedProducts: [],
    searchSuccess: false,
    loading: false,
    err: "",
    success: false,
};

export const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                salesData: action.payload.data,
                totalNumberOfDocs: action.payload.results,
            };
        case GET_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload,
            };
        case GET_SALE_DETAILS:
            return {
                ...state,
                saleDetails: action.payload,
            };
        case PRODUCT_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PRODUCT_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
                err: ''
            };
        case PRODUCT_SEARCH_FAILURE:
            return { ...state, loading: false, err: action.payload };
        case SCAN_BARCODE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SCAN_BARCODE_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
                searchSuccess: true,
            };
        case SCAN_BARCODE_FAILURE:
            return { ...state, loading: false, err: action.payload };
        case CLEAR_SEARCH_RESULT:
            return {...state,products: []}
        case RESET_SUCCESS:
            return {...state, searchSuccess:false,success:false}
        case ADD_SALES_PRODUCT_REQUEST:
            return { ...state, loading: true };
        case ADD_SALES_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                addedProducts: action.payload,
            };
        case ADD_SALES_PRODUCT_FAILURE:
            return { ...state, loading: false, err: action.payload };
        case CLEAR_PRODUCTS:
            return {...state,addedProducts: []}
        case REMOVE_SALES_PRODUCT_REQUEST:
            return { ...state, success: false, loading: true };
        case REMOVE_SALES_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                addedProducts: action.payload,
            };
        case REMOVE_SALES_PRODUCT_FAILURE:
            return { ...state, loading: false, err: action.payload };
        case CREATE_SALES_REQUEST:
            return { ...state, success: false, loading: true };
        case CREATE_SALES_SUCCESS:
            return { ...state, loading: false, success: true, addedProducts:[] };
        case CREATE_SALES_FAILURE:
            return { ...state, loading: false, err: action.payload };
        default:
            return state;
    }
};
