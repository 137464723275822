import {
    FILE_UPLOAD_FAILURE,
    FILE_UPLOAD_REQUEST,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_RESET
} from "../constants/importExcel.constant";

const initialState = {
    loading: false,
    success: false,
    err: ''
}

export const importExcelReducer = (state = initialState, action) => {
    switch(action.type){
        case FILE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            }
        case FILE_UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload
            }
        case FILE_UPLOAD_RESET:
            return {
                ...state,
                success: false
            }
        default:
            return state;
    }
}