import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from '@redux-devtools/extension';
import { authenticationReducer } from "./reducer/auth.reducer";
import { dashboardReducer } from "./reducer/dashboard.reducer";
import { productReducer} from './reducer/product.reducer';
import { importExcelReducer } from "./reducer/importexcel.reducer";
import { salesReducer } from './reducer/sales.reducer';
import { userReducer } from "./reducer/user.reducer";


//using redux thunk as middleware...
const middleware = [thunk];

//combining reducers
const reducer = combineReducers({
    auth: authenticationReducer,
    dashboard:dashboardReducer,
    productReducer,
    importExcelReducer,
    salesReducer,
    userReducer
})

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;


