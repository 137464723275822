export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST';
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS';
export const PRODUCT_SEARCH_FAILURE = 'PRODUCT_SEARCH_FAILURE';

export const ADD_SALES_PRODUCT_REQUEST = 'ADD_SALES_PRODUCT_REQUEST';
export const ADD_SALES_PRODUCT_SUCCESS = 'ADD_SALES_PRODUCT_SUCCESS';
export const ADD_SALES_PRODUCT_FAILURE = 'ADD_SALES_PRODUCT_FAILURE';

export const REMOVE_SALES_PRODUCT_REQUEST = 'REMOVE_SALES_PRODUCT_REQUEST';
export const REMOVE_SALES_PRODUCT_SUCCESS = 'REMOVE_SALES_PRODUCT_SUCCESS';
export const REMOVE_SALES_PRODUCT_FAILURE = 'REMOVE_SALES_PRODUCT_FAILURE';

export const CREATE_SALES_REQUEST = 'CREATE_SALES_REQUEST';
export const CREATE_SALES_SUCCESS = 'CREATE_SALES_SUCCESS';
export const CREATE_SALES_FAILURE = 'CREATE_SALES_FAILURE';

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_FAILURE = 'GET_SALES_FAILURE';

export const SCAN_BARCODE_REQUEST = 'SCAN_BARCODE_REQUEST';
export const SCAN_BARCODE_SUCCESS = 'SCAN_BARCODE_SUCCESS';
export const SCAN_BARCODE_FAILURE = 'SCAN_BARCODE_FAILURE';

export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const RESET_SUCCESS = 'RESET_SUCCESS';

export const GET_SALE_DETAILS = 'GET_SALE_DETAILS';
