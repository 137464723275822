import {
    PURCHASE_FAILURE,
    PURCHASE_REQUEST,
    PURCHASE_SUCCESS,
    ADD_PRODUCT_FAILURE,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    RESET_SUCCESS,
    SEARCH_PURCHASE_FAILURE,
    SEARCH_PURCHASE_REQUEST,
    SEARCH_PURCHASE_SUCCESS,
    FILTERED_PRODUCT_FAILURE,
    FILTERED_PRODUCT_REQUEST,
    FILTERED_PRODUCT_SUCCESS,
    SEARCH_STORE_PURCHASE_FAILURE,
    SEARCH_STORE_PURCHASE_REQUEST,
    SEARCH_STORE_PURCHASE_SUCCESS,
    GET_STORES_FAILURE,
    GET_STORES_REQUEST,
    GET_STORES_SUCCESS,
    SEARCH_STORE_FILTER_REQUEST,
    SEARCH_STORE_FILTER_SUCCESS,
    SEARCH_STORE_FILTER_FAILURE
} from "../constants/dashboard.constant";

const initialState = {
    purchases: [],
    sales: [],
    stores: [],
    loading: false,
    success: false,
    err: "",
    totalnumber: 0,
};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case PURCHASE_REQUEST:
            return { ...state, loading: true };
        case PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                purchases: action.payload.data,
                totalnumber: action.payload.results,
                err: ''
            };
        case PURCHASE_FAILURE:
            return {
                ...state,
                loading: false,
                error: "Please try again! something went wrong",
            };
        case FILTERED_PRODUCT_REQUEST:
            return { ...state, loading: true };
        case FILTERED_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                purchases: action.payload.data,
                totalnumber: action.payload.results,
            };
        case FILTERED_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SEARCH_STORE_FILTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SEARCH_STORE_FILTER_SUCCESS:
            return {
                ...state,
                loading:false,
                purchases: action.payload.data,
                totalnumber: action.payload.totalResults
            }
        case SEARCH_STORE_FILTER_FAILURE:
            return {
                ...state,
                loading:false,
                error: action.payload
            }
        case GET_STORES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_STORES_SUCCESS:
            return {
                ...state,
                loading: false,
                stores: action.payload,
            };
        case GET_STORES_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload
            };
        case ADD_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ADD_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                err: "We are having a problem right now please try again after sometime!",
            };
        case SEARCH_PURCHASE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SEARCH_PURCHASE_SUCCESS:
            return {
                err:'',
                ...state,
                loading: false,
                purchases: action.payload,
            };
        case SEARCH_PURCHASE_FAILURE:
            return {
                ...state,
                loading: false,
                err: action.payload,
                purchases: []
            };
        case SEARCH_STORE_PURCHASE_REQUEST:
            return {
                ...state,
                loading: true,
                err:''
            };
        case SEARCH_STORE_PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                purchases: action.payload,
                err:''
            };
        case SEARCH_STORE_PURCHASE_FAILURE:
            return {
                ...state,
                purchases: [],
                loading: false,
                err: action.payload,
            };
        case RESET_SUCCESS:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
    }
};
