import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loading = () => {
    return (
        <>
            <Box sx={{
                width: 200,
                height: 200,
                position: 'absolute',
                top:'40%',
                left: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                backgroundColor: '#adb5bd'
            }}>
                <CircularProgress />
            </Box>
        </>
    );
};

export default Loading;
