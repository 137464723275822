export const PURCHASE_REQUEST = 'PURCHASE_REQUEST';
export const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
export const PURCHASE_FAILURE = 'PURCHASE_FAILURE';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const SEARCH_PURCHASE_REQUEST = 'SEARCH_PURCHASE_REQUEST';
export const SEARCH_PURCHASE_SUCCESS = 'SEARCH_PURCHASE_SUCCESS';
export const SEARCH_PURCHASE_FAILURE = 'SEARCH_PURCHASE_FAILURE';

export const SEARCH_STORE_PURCHASE_REQUEST = 'SEARCH_STORE_PURCHASE_REQUEST';
export const SEARCH_STORE_PURCHASE_SUCCESS = 'SEARCH_STORE_PURCHASE_SUCCESS';
export const SEARCH_STORE_PURCHASE_FAILURE = 'SEARCH_STORE_PURCHASE_FAILURE';

export const SEARCH_STORE_FILTER_REQUEST = 'SEARCH_STORE_FILTER_REQUEST';
export const SEARCH_STORE_FILTER_SUCCESS = 'SEARCH_STORE_FILTER_SUCCESS';
export const SEARCH_STORE_FILTER_FAILURE = 'SEARCH_STORE_FILTER_FAILURE';


export const FILTERED_PRODUCT_REQUEST = 'FILTERED_PRODUCT_REQUEST';
export const FILTERED_PRODUCT_SUCCESS = 'FILTERED_PRODUCT_SUCCESS';
export const FILTERED_PRODUCT_FAILURE = 'FILTERED_PRODUCT_FAILURE';

export const RESET_SUCCESS = 'RESET_SUCCESS';

export const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';
